export default {
    title: 'Social Impact',
    location: '/people/social-impact',
    tabs: [
        {
            name: 'By Country',
            link: `/people/social-impact`
        },
        {
            name: 'Trend',
            link: `/people/social-impact/:market`
        }
    ]
}